import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";
import { Row, Col } from "reactstrap";
import PageTitleSection from "../components/PageTitleSection";
import truckImage from "../images/truck.png";
import truckNight from "../images/truck-night.jpg";

const Section1 = () => {
  return (
    <Section className="service-section1">
      <Row>
        <Col lg={6}>
          <div className="ss1-item">
            <h2>Truckload Shipping Services</h2>
            <p>
              ENS Logistics offers a full range of truckload services Across
              North America. Our dedication to provide nothing but the best
              possible shipping options is what makes the difference for our
              customers. Businesses count on ENS Logistics to continually
              maximize production and warehousing efficiencies through the
              reliable and punctual pick up and delivery of their freight. As a
              boutique brokerage, it’s our responsiveness and relationships that
              sets our premium level services apart from our competition.
            </p>
          </div>
          <div className="ss1-item">
            <h2>Truckload Service Benefits</h2>
            <p>
              Access to a full range of dry van and specialized equipment
              including tandem and tridem dry vans, heated, refrigerated and
              temperature controlled trailers, as well as flatbeds, drop decks
              and step decks
            </p>
            <ul>
              <li>
                Access to team service for expedited and time sensitive
                deliveries
              </li>
              <li>
                Access to single driver service for more cost effective
                transport
              </li>
              <li>
                Secure transport of pharmaceutical, retail and food products
              </li>
              <li>Efficient appointment delivery capabilities</li>
              <li>Reliable scheduled customer runs to reduce costs</li>
            </ul>
          </div>
          {/* <div className="ss1-item">
            <h2>LTL Shipping</h2>
            <p>
              If your shipment does not require a full trailer, ENS Logistics
              can provide a cost effective solution for your unique
              requirements. Our LTL service can handle all of your domestic and
              cross-border freight and reaches all major North American
              metropolitan areas.{" "}
            </p>
            <p>
              ENS Logistics has built strong alliances with partner carriers all
              across North America, giving customers a wide range of LTL
              shipping options. With our LTL service, we are able to offer
              different solutions based on your needs. We have partner carriers
              who operate in a traditional hub and spoke model; offering cost
              effective, reliable service at competitive transit times and
              pricing. We also have partners who run direct to and from the US,
              offering less cross docking, which results in faster transit times
              and less risk of damage. Finally, for those hot shipments that are
              time sensitive and have a high risk of damage, we offer dedicated
              full load, direct haul expedited service.
            </p>
          </div> */}
        </Col>
        <Col lg={6}>
          <img src={truckImage} className="img-fluid" alt="" />
          <div className="horizontal-line" />
          <img src={truckNight} className="img-fluid" alt="" />
        </Col>
      </Row>
    </Section>
  );
};

const Services = () => (
  <Layout>
    <SEO title="About" />
    <PageTitleSection title="SERVICES" />
    <Section1 />
  </Layout>
);

export default Services;
